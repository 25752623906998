"use client";
import React, { useContext, useEffect, useState, Suspense } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import styles from "./App.module.scss";
import LoadingSpinner from "./components/atoms/LoadingSpinner/LoadingSpinner";
import ScrollIntoView from "./components/atoms/ScrollIntoView/ScrollIntoView";
import HomePage from "./components/pages/home/Home";
import { SetGraphUser, SetIsAdmin, SetUserPreferences } from "./context/ActionTypes";
import { AdwContext, AdwContextType } from "./context/AdwContext";
import InsideDataService from "./services/InsideDataService";
import GraphService from "./services/GraphService";
import AuthService from "./services/AuthService";
import MainErrorMessage from "./components/organisms/MainErrorMessage/MainErrorMessage";
import EnvironmentService from "./services/EnvironmentService";
import { TimeUnits } from "./types/CachingTypes/TimeUnits";
import EmployeeDetailView from "./components/organisms/EmployeeDetailView/EmployeeDetailView";
import DiagnosticView from "./components/organisms/Diagnostics/DiagnosticsView";
import * as MsalAuthService from "./services/MsalAuthService"
import CallupErrorMessage from "./components/organisms/CallupErrorMessage/CallupErrorMessage";
import NewSearch from "./components/organisms/NewSearch/NewSearch";
import AtoZView from "./components/organisms/AtoZView/AtoZView";
import CalendarEvents from "./components/organisms/CalendarEventView/CalendarEvents";
import ErrorReportingService from "./services/ErrorReportingService";
import { ErrorBoundary } from "react-error-boundary";
import NwOrgChart from "./components/organisms/NwOrgChart/NwOrgChart";
import DefaultLayout from "./components/layouts/DefaultLayout/DefaultLayout";
import NwCustomizeCommonToolsView from "./components/organisms/NwCommonTools/NwCommonToolsView";
import SeeAllAnnouncementsView from "./components/organisms/SeeAllAnnouncements/SeeAllAnnouncementsView";
import NonPrimaryApp from "./NonPrimaryApp";

const App: React.FC = () => { // NOSONAR
  const [appState, dispatch] = useContext<AdwContextType>(AdwContext);

  useEffect(() => {
    const uri: string = EnvironmentService.getMindBreezeSearchUrl();
    const script = document.createElement("script");
    script.src = uri + "/search/apps/scripts/client.js";
    script.async = true;
    document.body.appendChild(script);

    addAnalyticsScript();
    let interval = setInterval(() => { 
      if (AuthService.hasActiveSession() && MsalAuthService.hasActiveMsalSession()) {
        if(localStorage.getItem("employeeType") === 'E' || localStorage.getItem("employeeType") === 'N'){
          GraphService.getAdwLoginID().then((userId) => {
            if (userId) {
              clearInterval(interval);
              InsideDataService.getUserAdminStatus(userId).then(isAdmin => {
              if (isAdmin.insideAdmin) {
                dispatch(SetIsAdmin(isAdmin.insideAdmin));
              }
              }).catch(err => {
                console.log("App >>> interval : Unable to retrieve admin status", err);
              })
              InsideDataService.getUserPreferences(userId).then(
                (adwUserPreferences) => {
                  dispatch(SetUserPreferences(adwUserPreferences, true));
                }
              );
            }
          });
        }
      }
    }, 100);

    let interval2 = setInterval(() => {
      if (MsalAuthService.hasActiveMsalSession() && appState && appState.lists) {
        clearInterval(interval2);
        if (!appState.user) {
          GraphService.getCurrentUser().then((user) => {
            dispatch(SetGraphUser(user));
          });
        }
        // Reload alerts every page load (no cache life)
        GraphService.getListItems("Alerts", dispatch, 0, TimeUnits.days, [
          "AlertLink",
          "AlertMessage",
          "AlertType",
          "EndDate",
          "id",
          "StartDate",
          "Target",
          "Title",
        ]);
        // Reload campaign banner every page load (no cache life)
        GraphService.getListItems("Campaign Banner", dispatch, 0, TimeUnits.days, [
          "id",
          "CampaignLink",
          "WindowTarget",
          "StartDate",
          "EndDate",
          "BackgroundColor",
          "ImagePath",
        ]);
        // Reload common tools every page load (no cache life)
        GraphService.getListItems(
          "Common Tools",
          dispatch,
          0, TimeUnits.days,
          [
            "Created",
            "DefaultTool",
            "id",
            "Link",
            "RequiredTool",
            "Title",
            "TooltipText",
            "WindowTarget",
            "ImagePath",
            "ToolHoverImagePath",
            "Position"
          ],
          "Position",
          true
        );
        // Reload popular tools every page load (no cache life)
        GraphService.getListItems(
          "Popular Tools",
          dispatch,
          0, TimeUnits.days,
          [
            "Title",
            "Enabled",
            "id",
            "Position",
            "AppUrl",
            "SiteTarget",
            "AvailableTo"
          ],
          "Position",
          true
        );
          // Reload What Changed every page load (no cache life)
          GraphService.getListItems(
            "What Changed",
            dispatch,
            1, TimeUnits.days,
            [
              "id",
              "Description",
              "ButtonLink",
              "DisplayPosition",
              "DisplayInApp",
              "WebElementID",
              "ImagePath",
              "ImageAltText",
              "StartDate",
              "EndDate",
              "Created"
            ],
            "DisplayPosition",
            true
          );
        // Reload company news every page load (no cache life)
        GraphService.getListItems(
          "Company News",
          dispatch,
          0, TimeUnits.days,
          [
            "id",
            "Title",
            "PublishDate",
            "StorySummary",
            "Link",
            "WindowTarget",
            "ImageAlternateText",
            "ImagePath",
            "Tags",
            "ContentTime",
            "ContentAction"
          ],
          "PublishDate",
          false,
          5
        );
        // Reload footer navigation if expired (3 day cache life)
        if (!appState.lists.footerNavigation || appState.lists.footerNavigationExpired) {
          GraphService.getListItems("Footer Navigation", dispatch, 3, TimeUnits.days, [
            "Heading",
            "HeadingOrder",
            "ItemOrder",
            "WindowTarget",
            "Item",
            "_ModerationStatus",
          ]);
        }
        // Reload site navigation if expired (1 day cache life)
        if (!appState.lists.siteNavigation || appState.lists.siteNavigationExpired) {
          GraphService.getListItems("Site Navigation", dispatch, 1, TimeUnits.days, [
            "id",
            "Category",
            "CategoryOrder",
            "SubCategory",
            "SubCategoryOrder",
            "Link",
            "ItemOrder",
            "LinkOrder",
            "Special",
            "WindowTarget",
          ]);
        }
        //Gets list of Sharepoint Sites with 0 day cache life
        if (!appState.lists.aToZView || appState.lists.aToZViewExpired) {
          GraphService.getListItems("AtoZ", dispatch, 0, TimeUnits.days, [
            "AtoZID",
            "URL",
            "SITENAME",
            "TARGET",
            "CATEGORY",
            "CONTACT",
            "DESCRIPTION"
          ], "SITENAME", true, 1000);
        }
        //Gets Sharepoint list - Calendar
        GraphService.getListItems(
          "Company Event",
          dispatch,
          0, TimeUnits.days,
          [
            "id",
            "DESCRIPTION",
            "CALENDAR_TITLE",
            "END_TIME",
            "EVENT_DATE",
            "EVENT_LOCATION_ID",
            "EVENT_TYPE_ID",
            "START_TIME",
            "RECURRING_EVENT_ID",
            "LOCATIONS",
            "TIME_ZONE"
          ]
        );
        //Gets Sharepoint list - Calendar
        GraphService.getListItems(
          "Company Event Type",
          dispatch,
          0, TimeUnits.days,
          [
            "EVENT_TYPE_ID",
            "EVENT_TYPE",
            "EVENT_COLOR"
          ]
        );
        if (!appState.lists.hrConnectLinks || appState.lists.hrConnectLinksExpired) {
          GraphService.getListItems("HRConnect Links", dispatch, 3, TimeUnits.days);
        }
        GraphService.getListItems("HRConnect Switches", dispatch, 0, TimeUnits.days, [
          "AllowRegex",
          "Widget",
          "StartDate",
          "EndDate",
          "EndTime",
          "Position",
          "DisplayWidget",
          "Title",
          "Description"
        ]);
        GraphService.getListItems("Feature Switches", dispatch, 0, TimeUnits.days, [
          "AllowRegex",
          "Widget",
          "Position",
          "DisplayWidget",
          "Feature",
          "Description",
          "Page",
          "Title",
          "FeatureSettings"
        ]);
      }
    }, 500);
  }, []);

  /* MyTech Notifications */
  useEffect(()=>{
    if(appState && (appState.graphUser.employeeType && (appState.graphUser.employeeType.toUpperCase() === 'E' || appState.graphUser.employeeType.toUpperCase() === 'N')) && appState.user ){
      GraphService.getMyTechListItems(
        "mytech_user_notifications_inside_data",
        dispatch,
        0, TimeUnits.days,
        [
          "id",
          "Title",
          "userGuid",
          "notificationData"
        ],
 
        {field:'userGuid',value:appState.graphUser.id}
      );
    } 
  },[appState.graphUser])


  const addAnalyticsScript = (): void => {
    const scripts: HTMLScriptElement = document.createElement("script");
    scripts.src = EnvironmentService.getAnalyticsScript();
    scripts.async = true;
    document.head.appendChild(scripts);
  }
  
  const [showNonPrimaryApp, setShowNonPrimaryApp] = useState(false);

  /* set up 30 sec timer before displaying NonPrimaryApp */
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNonPrimaryApp(true);
    }, 30000);
    return () => clearTimeout(timer);
  }, []);
  
  /* check if user is logged in and has an employee type */
  if(MsalAuthService.hasActiveMsalSession() && appState.graphUser.employeeType && (appState.graphUser.employeeType.toUpperCase() === 'E' || appState.graphUser.employeeType.toUpperCase() === 'N')){
    return (appState && (appState.graphUser.employeeType && (appState.graphUser.employeeType.toUpperCase() === 'E' || appState.graphUser.employeeType.toUpperCase() === 'N')) && appState.user ? (
      <ErrorBoundary FallbackComponent={MainErrorMessage} onError={(error, info) => {
        ErrorReportingService.reportErrorWithMessage(
          error.toString(),
          "ErrorBoundary.tsx -> MainErrorMessage -> BrowserRouter App.tsx",
          appState.user.nwieID
        )
      }}>
        <BrowserRouter>
          <ScrollIntoView>
            <ErrorBoundary FallbackComponent={MainErrorMessage} onError={(error, info) => {
              ErrorReportingService.reportErrorWithMessage(
                error.toString(),
                "ErrorBoundary.tsx -> MainErrorMessage -> Routes",
                appState.user.nwieID
              )
            }}>
              <Suspense fallback={<LoadingSpinner/>}>
                <Routes>
                  <Route
                    path="/"
                    element={<DefaultLayout />}
                  >
                    <Route
                      path="/"
                      element={<HomePage className={styles.padded} />}
                    />
                    <Route
                      path="/customizetools"
                      element={
                        <NwCustomizeCommonToolsView />
                      }
                    />
                    <Route
                      path="/SeeAllAnnouncements"
                      element={
                        <SeeAllAnnouncementsView />
                      }
                    />
                    <Route
                      path="/diagnostic"
                      element={
                        <DiagnosticView className={styles.padded} />
                      }
                    />
                    <Route
                      path="/employeeDetail"
                      element={<ErrorBoundary FallbackComponent={CallupErrorMessage} onError={(error, info) => {
                        ErrorReportingService.reportErrorWithMessage(
                          error.toString(),
                          "ErrorBoundary.tsx -> CallupErrorMessage -> EmployeeDetailView",
                          appState.user.nwieID
                        )
                      }}>
                        <EmployeeDetailView />
                      </ErrorBoundary>
                      }
                    />
                    <Route
                      path="/SearchResults"
                      element={<NewSearch />}
                    />
                    <Route
                      path="/AtoZ"
                      element={
                        <AtoZView />
                      }
                    />
                    <Route
                      path="/event-calendar"
                      element={
                        <CalendarEvents />
                      }
                    />
                    <Route
                      path="/org-chart"
                      element={<NwOrgChart currentAuthenticatedUser={appState && appState.user} />
                      }
                    />
                  </Route>
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </ScrollIntoView>
        </BrowserRouter>
      </ErrorBoundary>
    ) : (
      <>
      {showNonPrimaryApp ? (     
        /* This will handle user's authentication issue on primary account */
        <NonPrimaryApp/>
      ) : (
        /* Skeleton */
        <LoadingSpinner/>
      )}
      </>
    ));
  }else if(MsalAuthService.hasActiveMsalSession() && appState.graphUser.employeeType){
    return (MsalAuthService.hasActiveMsalSession() && appState && appState.graphUser ? (
      <NonPrimaryApp />
    ) : (
      <LoadingSpinner />
    ));
  }
}

export default App;