import { DispatchAction } from "../context/AdwContextTypes";
import GraphRecentDocs from "../types/GraphTypes/GraphRecentDoc";
import { DirectReportAssociateData, GraphUser, GraphUserData, getDefaultGraphUser ,GraphUserPersonalizedEmail, AvatarAssociateData, GraphAvatarData} from "../types/GraphTypes/GraphUser";
import EnvironmentService from "./EnvironmentService";
import CachingService from "./CachingService";
import { TimeUnits } from "../types/CachingTypes/TimeUnits";
import { SpAlert } from "../types/AlertTypes/SpAlert";
import AdwAlert from "../types/AlertTypes/AdwAlert";
import { SpCampaignBanner } from "../types/CampaignBannerTypes/SpCampaignBanner";
import AdwCampaignBanner from "../types/CampaignBannerTypes/AdwCampaignBanner";
import { SpCommonTool } from "../types/CommonToolsTypes/SpCommonTool";
import AdwCommonTool from "../types/CommonToolsTypes/AdwCommonTool";
import { SpNewsStory } from "../types/CompanyNewsTypes/SpNewsStory";
import AdwNewsStory from "../types/CompanyNewsTypes/AdwNewsStory";
import { SpFooterMenuItem } from "../types/FooterTypes/SpFooterMenuItem";
import AdwFooterMenuItem from "../types/FooterTypes/AdwFooterMenuItem";
import { LocationName } from "../types/Locations/LocationName";
import { SpMegaMenuItem } from "../types/MegaMenuTypes/SpMegaMenuItem";
import AdwMegaMenuItem from "../types/MegaMenuTypes/AdwMegaMenuItem";
import AdwMegaMenuService from "./AdwMegaMenuService";
import { MegaMenuCategory } from "../types/MegaMenuTypes/MegaMenuCategory";
import { SetSpListContents } from "../context/ActionTypes";
import { AboutMeType } from "../types/AboutMeTypes/AboutMeType";
import ErrorReportingService from "./ErrorReportingService";
import { getAccessToken } from "./MsalAuthService";
import { SpHrConnectLink } from "../types/FooterTypes/SpHrConnectLink";
import AdwHrConnectLink from "../types/FooterTypes/AdwHrConnectLink";
import { SpAtoZView } from "../types/AtoZViewTypes/SpAtoZView";
import AdwAtoZView from "../types/AtoZViewTypes/AdwAtoZView";
import { SpHrConnectSwitch } from "../types/HRConnectTypes/SpHrConnectSwitch";
import { AdwHrConnectSwitch } from "../types/HRConnectTypes/AdwHrConnectSwitch";
import { SpCalendarEvent } from "../types/CalendarTypes/SpCalendarEvent";
import { SpCalendarEventType } from "../types/CalendarTypes/SpCalendarEventType";
import AdwCalendarEventType from "../types/CalendarTypes/AdwCalendarEventType";
import { SpFeatureSwitch } from "../types/FeatureSwitchTypes/SpFeatureSwitch";
import { AdwFeatureSwitch } from "../types/FeatureSwitchTypes/AdwFeatureSwitch";
import BrowserUtils from "../utils/BrowserUtils";
import TimingUtils from "../utils/TimingUtils";
import AdwFcEvent from "../types/CalendarTypes/AdwFcEvent";
import AdwPopularTool from "../types/PopularToolsTypes/AdwPopularTool";
import { SpPopularTool } from "../types/PopularToolsTypes/SpPopularTool";
import AdwWhatChanged from "../types/WhatChangedTypes/AdwWhatChanged";
import { SpWhatChanged } from "../types/WhatChangedTypes/SpWhatChanged";
import { SpMyTechNotification } from "../types/NotificationTypes/SpMyTechNotification";
import AdwMyTechNotification from "../types/NotificationTypes/AdwMyTechNotification";
import { SpAnnouncements } from "../types/AnnouncementsTypes/SpAnnouncements";
import AdwAnnouncement from "../types/AnnouncementsTypes/AdwAnnouncement";

const missingPerson: string = require("../assets/images/NoPhoto.png");

const graphApiRoot = "https://graph.microsoft.com/v1.0";

export default class GraphService {

  public static getGraphHeaders(numberOfCallAttempts: number = 0): Promise<RequestInit> {
    return getAccessToken().then(accessToken => {
      //Check if the response is null or has errors, if it is/does, retry getting the access token up to 5 times 
      if (numberOfCallAttempts >= 5) {
        //Will return a 401 error on trying to make the request but prevents the page from erroring due to resp being null..
        //Future: Possibly add logging to Splunk with error and user data
        return {
          headers: {
            "Content-Type": `application/json`,
            "ConsistencyLevel":`eventual`,
          },
        }
      }
      else if (!accessToken) {
        return this.getGraphHeaders(numberOfCallAttempts + 1);
      }
      else {
        return {
          headers: {
            Authorization: accessToken,
            "Content-Type": `application/json`,
            "ConsistencyLevel":`eventual`,
          },
        };
      }
    });
  }

  public static getGraphHeadersForUpdate(numberOfCallAttempts: number = 0): Promise<RequestInit> {
    return getAccessToken().then(accessToken => {
      //Check if the response is null or has errors, if it is/does, retry getting the access token up to 5 times 
      if (numberOfCallAttempts >= 5) {
        //Will return a 401 error on trying to make the request but prevents the page from erroring due to resp being null..
        //Future: Possibly add logging to Splunk with error and user data
        return {
          headers: {
            "Content-Type": `application/json`,
            // Accept header: Specifies the format for response data from the server.
            "Accept": "application/json;odata=verbose",
            //Content-Type header: Specifies the format of the data that the client is sending to the server
            //"Content-Type": "application/json;odata=verbose",
            // IF-MATCH header: Provides a way to verify that the object being changed has not been changed since it was last retrieved.
            // "IF-MATCH":"*", will overwrite any modification in the object, since it was last retrieved.
            //"IF-MATCH": "*",
            //X-HTTP-Method:  The MERGE method updates only the properties of the entity , while the PUT method replaces the existing entity with a new one that you supply in the body of the POST
            //"X-HTTP-Method": "MERGE"
            // X-RequestDigest header: When you send a POST request, it must include the form digest value in X-RequestDigest header
            //"X-RequestDigest": $("#__REQUESTDIGEST").val()
          },
        }
      }
      else if (!accessToken) {
        return this.getGraphHeaders(numberOfCallAttempts + 1);
      }
      else {
        return {
          headers: {
            Authorization: accessToken,
            "Content-Type": `application/json`,
          },
        };
      }
    });
  }

  public static getProfilePicture(): Promise<string> {
    return GraphService.getGraphHeaders().then((headers) => {
      return fetch(`${graphApiRoot}/me/photo/$value`, headers)
        .then((response) => {
          if (!response.ok) {
            ErrorReportingService.reportErrorWithResponse(
              response,
              "GraphService.ts -> getProfilePicture",
              `Attempted to retrieve: ${response.url}`
            );
          }
          return response;
        })
        .then(checkOKResponse)
        .then(response => response.arrayBuffer())
        .then((buffer) => {
          return `data:image/jpeg;base64,${arrayBufferToBase64(buffer)}`;
        })
        .catch((err) => {
          console.log("GraphService >>> getProfilePicture : Error while retrieving profile image", err);
          return "";
        });
    });
  }

  public static getUsersPhoto(nwie: string): Promise<string> | string {
    if (nwie && nwie.trim()) {
      /* if parameter is user shortname */
      if(nwie.indexOf('@') < 0){
        nwie += '@nationwide.com';
      }
      return GraphService.getGraphHeaders().then(headers => {
        return fetch(`${graphApiRoot}/users/${nwie}/photo/$value`, headers)
          .then((response) => {
            if (!response.ok) {
              ErrorReportingService.reportErrorWithResponse(
                response,
                "GraphService.ts -> getUsersPhoto",
                `Attempted to retrieve: ${response.url}`
              );
            }
            return response;
          })
          .then(checkOKResponse)
          .then((response) => {
            return response.arrayBuffer();
          })
          .then((buffer) => {
            return `data:image/jpeg;base64,${arrayBufferToBase64(buffer)}`;
          })
          .catch((err) => {
            return `${missingPerson}`;
          });
      });
    }
    else {
      return missingPerson
    }
  }

  public static getListItems( // NOSONAR
    listName: string,
    dispatch: React.Dispatch<DispatchAction>,
    cacheDuration: number,
    durationUnit: TimeUnits,
    select: Array<string> = [],
    orderBy: string = "",
    asc: boolean = false,
    top: number = 0,
    filter: { field: string; value: string } = null
  ): void {
    try {
      if (!listName || !listName.trim()) {
        console.log("GraphService >>> getListItems: Invalid list name");
        return
      }
      let url: string = `${graphApiRoot}/sites/${EnvironmentService.getSiteId()}/lists/${listName}/items?select=id&expand=fields`;
      if (select && select.length > 0) {
        url = `${url}(select=${select.join(",")})`;
      }
      if (orderBy && orderBy.trim()) {
        url = `${url}&$orderBy=fields/${orderBy} ${asc ? "asc" : "desc"}`;
      }
      if (top > 0) {
        url = `${url}&$top=${top}`;
      }
      if (filter && filter.field && filter.value && filter.field.trim() && filter.value.trim()) {
        url = `${url}&filter=fields/${filter.field} eq '${filter.value}'`;
      }
      GraphService.getGraphHeaders().then(headers => {
        fetch(url, headers)
          .then((response) => {
            if (!response.ok) {
              ErrorReportingService.reportErrorWithResponse(
                response,
                "GraphService.ts -> getListItems",
                `Attempted to retrieve: ${response.url}`
              );
            }
            return response;
          })
          .then(checkOKResponse)
          .then((response) => response.json())
          .then((response) => {
            let itemsArray: any[] = response && response.value ? response.value : [];
            if (itemsArray) {
              switch (listName.toLowerCase()) {
                case "alerts":
                  const now: number = new Date().getTime();
                  itemsArray = itemsArray
                    .map((item: SpAlert) => new AdwAlert(item))
                    .filter(
                      (alert) =>
                        alert && alert.StartDate && alert.EndDate &&
                        new Date(alert.StartDate).getTime() < now &&
                        new Date(alert.EndDate).getTime() > now
                    );
                  CachingService.saveValueToCache<AdwAlert[]>(
                    itemsArray,
                    "Alerts",
                    cacheDuration,
                    durationUnit
                  );
                  break;
                case "atoz":
                  //when API call is made, sort by siteName
                  itemsArray = itemsArray
                    .map((item: SpAtoZView) => new AdwAtoZView(item));
                  CachingService.saveValueToCache<AdwAtoZView[]>(
                    itemsArray,
                    "AtoZ",
                    cacheDuration,
                    durationUnit
                  );
                  if (!BrowserUtils.isIframe()) {
                    TimingUtils.registerComponentLoad('AtoZ');
                  };
                  break;
                case "company event":
                  //when API call is made, sort by siteName
                  itemsArray = itemsArray
                    .map((item: SpCalendarEvent) => new AdwFcEvent(item));
                  CachingService.saveValueToCache<AdwFcEvent[]>(
                    itemsArray,
                    "Company Event",
                    cacheDuration,
                    durationUnit
                  );
                  break;
                case "company event type":
                  //when API call is made, sort by siteName
                  itemsArray = itemsArray
                    .map((item: SpCalendarEventType) => new AdwCalendarEventType(item));
                  CachingService.saveValueToCache<AdwCalendarEventType[]>(
                    itemsArray,
                    "Company Event Type",
                    cacheDuration,
                    durationUnit
                  );
                  break;
                case "campaign banner":
                  const todaysDate: Date = new Date();
                  itemsArray = itemsArray.map(
                    (item: SpCampaignBanner) => new AdwCampaignBanner(item)
                  ).filter(banner => banner && banner.StartDate && banner.EndDate && todaysDate >= new Date(banner.StartDate) && todaysDate <= new Date(banner.EndDate));
                  CachingService.saveValueToCache<AdwCampaignBanner[]>(
                    itemsArray,
                    "Campaign Banner",
                    cacheDuration,
                    durationUnit
                  );
                  break;
                case "common tools":
                  itemsArray = itemsArray.map((item: SpCommonTool) => {
                    return new AdwCommonTool(item);
                  });
                  CachingService.saveValueToCache<AdwCommonTool[]>(
                    itemsArray,
                    "Common Tools",
                    cacheDuration,
                    durationUnit
                  );
                  if (!BrowserUtils.isIframe()) {
                    TimingUtils.registerComponentLoad('Tools');
                  };
                  break;
                case "popular tools":
                  itemsArray = itemsArray.map((item: SpPopularTool) => {
                    return new AdwPopularTool(item);
                  });
                  CachingService.saveValueToCache<AdwPopularTool[]>(
                    itemsArray,
                    "Popular Tools",
                    cacheDuration,
                    durationUnit
                  );
                  if (!BrowserUtils.isIframe()) {
                    TimingUtils.registerComponentLoad('PopularTools');
                  };
                  break;
                case "what changed":
                  itemsArray = itemsArray.map((item: SpWhatChanged) => {
                    return new AdwWhatChanged(item);
                  });
                  CachingService.saveValueToCache<AdwWhatChanged[]>(
                    itemsArray,
                    "What Changed",
                    cacheDuration,
                    durationUnit
                  );
                  break;
                case "company news":
                  itemsArray = itemsArray.map(
                    (item: SpNewsStory) => new AdwNewsStory(item)
                  );
                  CachingService.saveValueToCache<AdwNewsStory[]>(
                    itemsArray,
                    "Company News",
                    cacheDuration,
                    durationUnit
                  );
                  if (!BrowserUtils.isIframe()) {
                    TimingUtils.registerComponentLoad('News');
                  }
                  break;
                case "footer navigation":
                  itemsArray = itemsArray.map(
                    (item: SpFooterMenuItem) => new AdwFooterMenuItem(item)
                  );
                  CachingService.saveValueToCache<AdwFooterMenuItem[]>(
                    itemsArray,
                    "Footer Navigation",
                    cacheDuration,
                    durationUnit
                  );
                  break;
                case "mailcodefloor":
                  if (!itemsArray || !itemsArray.length) {
                    itemsArray = [
                      { Location: filter && filter.value ? filter.value : "", NwBuildName: null },
                    ] as LocationName[];
                  }
                  break;
                case "site navigation":
                  itemsArray = itemsArray.map(
                    (item: SpMegaMenuItem) => new AdwMegaMenuItem(item)
                  );
                  itemsArray = AdwMegaMenuService.buildMenuStructure(itemsArray);
                  CachingService.saveValueToCache<MegaMenuCategory[]>(
                    itemsArray,
                    "Site Navigation",
                    cacheDuration,
                    durationUnit
                  );
                  break;
                case "hrconnect links":
                  itemsArray = itemsArray.map(
                    (item: SpHrConnectLink) => new AdwHrConnectLink(item)
                  );
                  CachingService.saveValueToCache<MegaMenuCategory[]>(
                    itemsArray,
                    "HRConnect Links",
                    cacheDuration,
                    durationUnit
                  );
                  break;
                case "hrconnect switches":
                  itemsArray = itemsArray.map(
                    (item: SpHrConnectSwitch) => new AdwHrConnectSwitch(item)
                  );
                  CachingService.saveValueToCache<AdwHrConnectSwitch[]>(
                    itemsArray,
                    "HRConnect Switches",
                    cacheDuration,
                    durationUnit
                  );
                  break;
                case "feature switches":
                  itemsArray = itemsArray.map(
                    (item: SpFeatureSwitch) => new AdwFeatureSwitch(item)
                  );
                  CachingService.saveValueToCache<AdwFeatureSwitch[]>(
                    itemsArray,
                    "Feature Switches",
                    cacheDuration,
                    durationUnit
                  );
                  break;
              }
              dispatch(SetSpListContents(listName, itemsArray));
            }
          })
          .catch((err) => {
            console.log(
              "GraphService >>> getListItems : Caught error retrieving contents from SharePoint list",
              listName,
              err
            );
          });
      });
    }
    catch (err) {
      console.log(
        "GraphService >>> getListItems : Caught error retrieving contents from SharePoint list",
        listName,
        err
      );
    }
  }

  public static getCompanyCalendarEvents(
    startDate: string,
    endDate: string,
    eventTypes: AdwCalendarEventType[],
    cacheDuration: number,
    durationUnit: TimeUnits,

  ): Promise<AdwFcEvent[]> {
    if (!startDate || !eventTypes) {
      return Promise.resolve(null)
    }
    let filterString: string = `/lists/Company Event/items?select=id&expand=fields&filter=fields/EVENT_DATE ge '${startDate}'`;

    if (endDate) {
      filterString += ` and fields/EVENT_DATE le '${endDate}'`;
    }
    return GraphService.getGraphHeaders().then(headers => {
      return fetch(
        `${graphApiRoot}/sites/${EnvironmentService.getSiteId()}${filterString}&orderBy=fields/EVENT_DATE%20asc`,
        headers
      )
        .then((response) => {
          if (!response.ok) {
            ErrorReportingService.reportErrorWithResponse(
              response,
              "GraphService.ts -> getMyCalendarEvents",
              `Attempted to retrieve: ${response.url}`
            );
          }
          return response;
        })
        .then(checkOKResponse)
        .then((response) => response.json())
        .then((response) => {
          let itemsArray: any[] = response && response.value ? response.value : [];
          itemsArray = itemsArray.map((item: SpCalendarEvent) => new AdwFcEvent(item, eventTypes));
          CachingService.saveValueToCache<AdwFcEvent[]>(
            itemsArray,
            "Company Event",
            cacheDuration,
            durationUnit
          );

          return itemsArray;
        })
        .catch((err) => {
          console.log("GraphService >>> getMyCalendarEvents : Error retrieving calendar events", err);
          return null;
        });
    })
  }

  public static async getAnnouncements(): Promise<AdwAnnouncement[]> {

    let filterString: string = `/lists/Announcements/items?select=id&expand=fields(select=id,Title,Pinned,Link)&filter=fields/StartDate le '${new Date().toLocaleString()}' and fields/EndDate ge '${new Date().toLocaleString()}'`;

    const userEmail = localStorage.getItem('userPrincipalName') ?? '';
    const isManager = await this.hasDirectReports(userEmail);
    
    if (!isManager) {
      filterString += " and fields/TargetAudience eq 'everyone'";
    }

    return GraphService.getGraphHeaders().then(headers => {
      return fetch(
        `${graphApiRoot}/sites/${EnvironmentService.getSiteId()}${filterString}&orderBy=fields/StartDate%20desc`,
        headers
      )
        .then((response) => {
          if (!response.ok) {
            ErrorReportingService.reportErrorWithResponse(
              response,
              "GraphService.ts -> getAnnouncements",
              `Attempted to retrieve: ${response.url}`
            );
          }
          return response;
        })
        .then(checkOKResponse)
        .then((response) => response.json())
        .then((response) => {
          let itemsArray: any[] = response?.value ? response.value : [];
          itemsArray = itemsArray.map((item: SpAnnouncements) => new AdwAnnouncement(item));
          CachingService.saveValueToCache<AdwAnnouncement[]>(
            itemsArray,
            "Announcements",
            0,
            TimeUnits.days
          );
          if (!BrowserUtils.isIframe()) {
            TimingUtils.registerComponentLoad('Announcements');
          };
          return itemsArray;
        })
        .catch((err) => {
          console.log("GraphService >>> getAnnouncements : Error retrieving announcements", err);
          return null;
        });
    })
  }


  public static getNextCompanyCalendarEvents(
    startDate: string,
    requestType: string, //"Company Event"
    numberOfRecord: number,
    eventTypes: AdwCalendarEventType[],
    cacheDuration: number,
    durationUnit: TimeUnits,
  ): Promise<AdwFcEvent[]> {
    if (!startDate || !eventTypes) {
      return Promise.resolve(null)
    }
    let filterString: string = `/lists/Company Event/items?top=${numberOfRecord}&select=id&expand=fields&filter=fields/EVENT_DATE ge '${startDate}'`;
    if (requestType == 'Pay Day') {
      filterString += ` and fields/CALENDAR_TITLE eq '${requestType}'`;
      requestType = 'next five payday';
    } else {
      requestType = 'next four event';
    }
    return GraphService.getGraphHeaders().then(headers => {
      return fetch(
        `${graphApiRoot}/sites/${EnvironmentService.getSiteId()}${filterString}&orderBy=fields/EVENT_DATE%20asc`,
        headers
      )
        .then((response) => {
          if (!response.ok) {
            ErrorReportingService.reportErrorWithResponse(
              response,
              "GraphService.ts -> getMyCalendarEvents",
              `Attempted to retrieve: ${response.url}`
            );
          }
          return response;
        })
        .then(checkOKResponse)
        .then((response) => response.json())
        .then((response) => {
          let itemsArray: any[] = response && response.value ? response.value : [];
          itemsArray = itemsArray.map((item: SpCalendarEvent) => new AdwFcEvent(item, eventTypes));
          CachingService.saveValueToCache<AdwFcEvent[]>(
            itemsArray,
            requestType,
            cacheDuration,
            durationUnit
          );

          return itemsArray;
        })
        .catch((err) => {
          console.log("GraphService >>> getNextCompanyCalendarEvents : Error retrieving calendar events", err);
          return null;
        });
    })
  }

  public static getRecentDocuments(): Promise<GraphRecentDocs[]> {
    return GraphService.getGraphHeaders().then(headers => {
      return fetch(
        `${graphApiRoot}/me/drive/recent?$top=6`,
        headers
      )

        .then((response) => {
          if (!response.ok) {
            ErrorReportingService.reportErrorWithResponse(
              response,
              "GraphService.ts -> getRecentDocuments",
              `Attempted to retrieve: ${response.url}`
            );
          }
          return response;
        })
        .then(checkOKResponse)
        .then((response) => response.json())
        .then((docs) => {
          return docs.value;
        })
        .catch((err) => {
          console.log("GraphService >>> getRecentDocuments : Failed to get recent documents", err);
          return [];
        });
    })
  }

  public static getCurrentUser(): Promise<GraphUser> {
    return GraphService.getGraphHeaders().then(headers => {
      return fetch(`${graphApiRoot}/me?$select=department,businessPhones,displayName,givenName,id,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,employeeType,surname,userPrincipalName,lastPasswordChangeDateTime`, headers)
        .then((response) => {
          if (!response.ok) {
            ErrorReportingService.reportErrorWithResponse(
              response,
              "GraphService.ts -> getCurrentUser",
              `Attempted to retrieve: ${response.url}`
            );
          }
          return response;
        })
        .then(checkOKResponse)
        .then((response) => response.json())
        .then((user: GraphUser) => {
          localStorage.setItem('employeeType', user.employeeType);
          localStorage.setItem('userPrincipalName', user.userPrincipalName);
          let emailAddress = user && user.mail;
          if (emailAddress && emailAddress.trim()) {
            const indexNumber: number = emailAddress.indexOf("@");
            if (indexNumber >= 0) {
              ErrorReportingService.setUser(emailAddress.substring(0, indexNumber));
              let currentUser = user.mail.substring(0, indexNumber);
              localStorage.setItem('currentUser', currentUser);
              if (currentUser != null && currentUser != "") {
                window['newrelic'].setCustomAttribute('user', currentUser);
              }
            }
          }
          return user;
        })
        .catch((error) => {
          console.log("GraphService >>> getCurrentUser : Got error retrieving graph user", error);
          return getDefaultGraphUser();
        });
    });
  }

  public static getAdwLoginID(): Promise<string> {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      return Promise.resolve(currentUser);
    }else {
      return GraphService.getCurrentUser().then((user) => {
        let adwLoginId: string = "";
        let graphUser: GraphUser = user ? user : getDefaultGraphUser();
        let emailAddress: string = graphUser ? graphUser.mail : "";
        if (emailAddress && emailAddress.trim()) {
          const indexNumber: number = emailAddress.indexOf("@");
          if (indexNumber >= 0) {
            adwLoginId = emailAddress.substring(0, indexNumber);
          }
        }
        return adwLoginId.trim().toLowerCase();
      })
      .catch((error) => {
        console.log("GraphService >>> getADWLoginID : Error retrieving User ID from Graph", error);
        return ""
      })
    }
}

  public static async getAboutMeData( // NOSONAR
    userPersonalizedEmail: string,
    triedBefore: boolean
  ): Promise<AboutMeType> {
    const defaultAbout = {
      aboutMe: null,
      birthday: null,
      interests: null,
      pastProjects: null,
      schools: null,
      skills: null,
    };
    if (!userPersonalizedEmail || !userPersonalizedEmail.trim()) {
      return Promise.resolve(defaultAbout)
    }
    const url: string = `${graphApiRoot}/users/${userPersonalizedEmail}?$select=aboutMe,birthday,pastProjects,schools,skills,interests`;

    return GraphService.getGraphHeaders().then(headers => {
      return fetch(url, headers)
        .then((response) => {
          if (!response.ok) {
            ErrorReportingService.reportErrorWithResponse(
              response,
              "GraphService.ts -> getAboutMeData",
              `Attempted to retrieve: ${response.url}`
            );
          }
          return response;
        })
        .then(checkOKResponse)
        .then((response) => response.json())
        .then((user: any) => {
          if (!user) {
            return defaultAbout
          }
          else if (user.error) {
            if (user.error.code === "ResourceNotFound" && !triedBefore) {
              if (userPersonalizedEmail && userPersonalizedEmail.trim()) {
                return GraphService.getAboutMeData(userPersonalizedEmail, true).then(
                  (vanityUser) => {
                    return vanityUser;
                  }
                );
              } else {
                return defaultAbout;
              }
            } else {
              return defaultAbout;
            }
          } else {
            return user;
          }
        })
        .catch((error) => {
          console.log("GraphService >>> getAboutMeData : Got error retrieving about me data", error);
          return defaultAbout;
        });
    });
  }

  public static getMyTechListItems( // NOSONAR
    listName: string,
    dispatch: React.Dispatch<DispatchAction>,
    cacheDuration: number,
    durationUnit: TimeUnits,
    select: Array<string> = [],

    filter: { field: string; value: string } = null
  ): void {
    try {
      if (!listName || !listName.trim()) {
        console.log("GraphService >>> getMyTechListItems: Invalid list name");
        return
      }
      let url: string = `${graphApiRoot}/sites/${EnvironmentService.getMyTechSiteId()}/lists/${listName}/items?select=id&expand=fields`;
      if (select && select.length > 0) {
        url = `${url}(select=${select.join(",")})`;
      }
      if (filter && filter.field && filter.value && filter.field.trim() && filter.value.trim()) {
        url = `${url}&filter=fields/${filter.field} eq '${filter.value}'`;
      }
      GraphService.getGraphHeaders().then(headers => {
        fetch(url, headers)
          .then((response) => {
            if (!response.ok) {
              ErrorReportingService.reportErrorWithResponse(
                response,
                "GraphService.ts -> getMyTechListItems",
                `Attempted to retrieve: ${response.url}`
              );
            }
            return response;
          })
          .then(checkOKResponse)
          .then((response) => response.json())
          .then((response) => {
            let itemsArray: any[] = response && response.value ? response.value : [];
            if (itemsArray) {
              if (listName.toLowerCase() === "mytech_user_notifications_inside_data") {

                //when API call is made, sort by siteName
                itemsArray = itemsArray
                .map((item: SpMyTechNotification) => new AdwMyTechNotification(item));
              CachingService.saveValueToCache<AdwMyTechNotification[]>(
                itemsArray,
                "mytech_user_notifications_inside_data",
                cacheDuration,
                durationUnit
              );
              }
              dispatch(SetSpListContents(listName, itemsArray));
            }
          })
          .catch((err) => {
            console.log(
              "GraphService >>> getMyTechListItems : Caught error retrieving contents from MyTech SharePoint list",
              listName,
              err
            );
          });
      });
    }
    catch (err) {
      console.log(
        "GraphService >>> getMyTechListItems : Caught error retrieving contents from MyTech SharePoint list",
        listName,
        err
      );
    }
  }


  public static getMyTechNotifications(
    userGuid: string,
    cacheDuration: number,
    durationUnit: TimeUnits,

  ): Promise<AdwFcEvent[]> {
    if (!userGuid) {
      return Promise.resolve(null)
    }
    let filterString: string = `/lists/MyTech_User_Notifications_Inside_Data/items?select=id&expand=fields(select=Title,notificationData,userGuid)&$filter=fields/userGuid eq '${userGuid}'`;

    return GraphService.getGraphHeaders().then(headers => {
      return fetch(
        `${graphApiRoot}/sites/${EnvironmentService.getMyTechSiteId()}${filterString}`,
        headers
      )
        .then((response) => {
          if (!response.ok) {
            ErrorReportingService.reportErrorWithResponse(
              response,
              "GraphService.ts -> getMyTechNotifications",
              `Attempted to retrieve: ${response.url}`
            );
          }
          return response;
        })
        .then(checkOKResponse)
        .then((response) => response.json())
        .then((response) => {
          let itemsArray: any[] = response && response.value ? response.value : [];
          itemsArray = itemsArray.map((item: SpMyTechNotification) => new AdwMyTechNotification(item));
          CachingService.saveValueToCache<AdwFcEvent[]>(
            itemsArray,
            "MyTech_User_Notifications_Inside_Data",
            cacheDuration,
            durationUnit
          );

          return itemsArray;
        })
        .catch((err) => {
          console.log("GraphService >>> getMyCalendarEvents : Error retrieving MyTech_User_Notifications_Inside_Data", err);
          return null;
        });
    })
  }

  public static getGraphUserPersonalizedEmail(onPremisesSamAccountName:string): Promise<any> {
    return GraphService.getGraphHeaders().then((headers) => {
      return fetch(`${graphApiRoot}/users?$filter=onPremisesSamAccountName%20eq%20%27${onPremisesSamAccountName}%27%20&$select=userPrincipalName,onPremisesSamAccountName&$count=true`, headers)
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "GraphService.ts -> getGraphUserPersonalizedEmail",
            `Attempted to retrieve: ${response.url}`
          );
        }
        return response;
      })
      .then(checkOKResponse)
      .then((response) => response.json())
      .then((userPersonalizedEmailData: GraphUserPersonalizedEmail) => {
        return userPersonalizedEmailData.value[0].userPrincipalName;
      })
      .catch((error) => {
        console.log("GraphService >>> getGraphUserPersonalizedEmail : Got error retrieving user personalized email data", error);
        return null;
      });
    });
  }
  
   public static getUserByEmail(associateEmail:string): Promise<GraphUserData> {
    return GraphService.getGraphHeaders().then((headers) => {
      return fetch(`${graphApiRoot}/users/${associateEmail}?$select=department,businessPhones,displayName,givenName,id,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,userPrincipalName,employeeType,onPremisesSamAccountName`, headers)
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "GraphService.ts -> getUserByEmail",
            `Attempted to retrieve: ${response.url}`
          );
        }
        return response;
      })
      .then(checkOKResponse)
      .then((response) => response.json())
      .then((user: GraphUserData) => {
          return user;
      })
      .catch((error) => {
        console.log("GraphService >>> getUserByEmail : Got error retrieving UserByEmail data", error);
        return null;
      });
    });
  }

  /* Get selected user profile - Avatar Orgchart  */
  public static getSelectedOrgChartUser(nwie:string): Promise<GraphAvatarData> {
    return GraphService.getGraphHeaders().then((headers) => {
      return GraphService.getGraphUserPersonalizedEmail(nwie).then(
        (userUPN : string) => {
          return fetch(`${graphApiRoot}/users/${userUPN}/?$expand=manager($select=displayName,jobTitle,userPrincipalName,employeeType,onPremisesSamAccountName)&$select=displayName,jobTitle,userPrincipalName,employeeType,onPremisesSamAccountName,id`, headers)
          .then((response) => {
            if (!response.ok) {
              ErrorReportingService.reportErrorWithResponse(
                response,
                "GraphService.ts -> getSelectedOrgChartUser",
                `Attempted to retrieve: ${response.url}`
              );
            }
            return response;
          })
          .then(checkOKResponse)
          .then((response) => response.json())
          .then((user: GraphAvatarData) => {
            return user;
          })
        })
      })
      .catch((error) => {
        console.log("GraphService >>> getSelectedOrgChartUser : Got error retrieving data", error);
        return null;
      });
    }

  public static getManager(managerEmail:string): Promise<GraphUserData> {
    return GraphService.getGraphHeaders().then((headers) => {
      return fetch(`${graphApiRoot}/users/${managerEmail}/manager?$select=department,businessPhones,displayName,givenName,id,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,userPrincipalName,employeeType,onPremisesSamAccountName`, headers)
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "GraphService.ts -> getManager",
            `Attempted to retrieve: ${response.url}`
          );
        }
        return response;
      })
      .then(checkOKResponse)
      .then((response) => response.json())
      .then((user: GraphUserData) => {
          return user;
      })
      .catch((error) => {
        console.log("GraphService >>> getManager : Got error retrieving manager data", error);
        return null;
      });
    });
  }

  /* Get manager chain up to the root level */
  public static getBossList(associateEmail:string): Promise<GraphAvatarData> {
    return GraphService.getGraphHeaders().then((headers) => {
      return fetch(`${graphApiRoot}/users/${associateEmail}/?$expand=manager($levels=max;$select=displayName,jobTitle,userPrincipalName,employeeType,onPremisesSamAccountName)&$select=displayName`, headers)
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "GraphService.ts -> getBossList",
            `Attempted to retrieve: ${response.url}`
          );
        }
        return response;
      })
      .then(checkOKResponse)
      .then((response) => response.json())
      .then((user: GraphAvatarData) => {
          return user;
      })
      .catch((error) => {
        console.log("GraphService >>> getBossList : Got error retrieving boss list data", error);
        return null;
      });
    });
  }

  public static getDirectReports(associateEmail:string): Promise<GraphUserData[]> {
    return GraphService.getGraphHeaders().then((headers) => {
      return fetch(`${graphApiRoot}/users/${associateEmail}/directReports?$select=department,businessPhones,displayName,givenName,id,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,userPrincipalName,employeeType,onPremisesSamAccountName&$filter=(employeeType eq 'E' or employeeType eq 'N') and accountEnabled eq true and extension_27d8898f82fe4d76a4e4e277e740070e_nwEmployeeStatus eq 'A'&$count=true&$orderby=displayName`, headers)
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "GraphService.ts -> getDirectReports",
            `Attempted to retrieve: ${response.url}`
          );
        }
        return response;
      })
      .then(checkOKResponse)
      .then((response) => response.json())
      .then((user: DirectReportAssociateData) => {
          return user.value;
      })
      .catch((error) => {
        console.log("GraphService >>> getDirectReports : Got error retrieving direct reports data", error);
        return null;
      });
    });
  }

  /* Determine if user has direct reports */
  public static hasDirectReports(associateEmail:string): Promise<boolean> {
    return GraphService.getGraphHeaders().then((headers) => {
      return fetch(`${graphApiRoot}/users/${associateEmail}/directReports/$count`, headers)
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "GraphService.ts -> hasDirectReports",
            `Attempted to retrieve: ${response.url}`
          );
        }
        return response;
      })
      .then(checkOKResponse)
      .then((response) => response.json())
      .then((directReportsCount) => {
        return directReportsCount > 0;
      })
      .catch((error) => {
        console.log("GraphService >>> hasDirectReports : Got error retrieving hasDirectReports data", error);
        return false;
      });
    });
  }



  /* Get Avatar orgchart selected user's direct reports */
  public static getAvatarDirectReports(associateEmail:string): Promise<GraphAvatarData[]> {
    return GraphService.getGraphHeaders().then((headers) => {
      return fetch(`${graphApiRoot}/users/${associateEmail}/directReports?$select=displayName,jobTitle,userPrincipalName,onPremisesSamAccountName,surname,employeeType&$filter=(employeeType eq 'E' or employeeType eq 'N') and accountEnabled eq true and extension_27d8898f82fe4d76a4e4e277e740070e_nwEmployeeStatus eq 'A'&$count=true&$orderby=displayName`, headers)
      .then((response) => {
        if (!response.ok) {
          ErrorReportingService.reportErrorWithResponse(
            response,
            "GraphService.ts -> getAvatarDirectReports",
            `Attempted to retrieve: ${response.url}`
          );
        }
        return response;
      })
      .then(checkOKResponse)
      .then((response) => response.json())
      .then((user: AvatarAssociateData) => {
        /* sort by surname */
        user.value.sort((a, b) => (a.surname).localeCompare(b.surname))
        return user.value;
      })
      .catch((error) => {
        console.log("GraphService >>> getAvatarDirectReports : Got error retrieving avatar direct reports data", error);
        return null;
      });
    });
  }
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  if (!buffer) {
    return ""
  }
  let binary = "";
  // Creates an array of from the buffer, and then calls slice
  // on the array with no params to copy it into the bytes variable
  let bytes = [].slice.call(new Uint8Array(buffer));
  // Converts character code into characters and builds binary string
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  // Base-64 encodes the binary string and returns it to the caller
  return window.btoa(binary);
}

export function checkOKResponse(response: Response) {
  if (!response || !response.ok) {
    throw new Error(`GraphService >>> checkOKResponse : Received Non 200 Response - ${response.status} - ${response.statusText}`);
  }
  return response;
}